<template>
  <div>
    <div class="row pl-5">
      <div class="col-12">
        <h2 class="font-weight-normal">
          Health Check
        </h2>
      </div>
    </div>
    <!-- Loader -->
    <div v-if="loading" class="row py-5 justify-content-center">
      <div class="col-10">
        <div class="ambulance">
          <font-awesome-icon icon="ambulance" size="4x" />
        </div>
      </div>
      <div class="col-2">
        <font-awesome-icon icon="hospital-alt" size="4x" />
      </div>
    </div>
    <!-- Health check tool -->
    <div v-if="!loading" class="row pt-3 pl-5">
      <div class="col-12 col-sm-2 col-md-3">
        <b-form-select v-model="agent" :options="cstAgents" />
      </div>
      <div class="col-12 col-sm-2 col-md-3">
        <b-form-input v-model="filterTable" type="text" placeholder="Search" />
      </div>
      <div class="col-12 col-sm-2 col-md-2">
        <button type="button" class="btn btn-outline-primary float-right" :disabled="selected.length === 0 || validActiveAccount"
                @click="activate">
          Make active
        </button>
      </div>
      <div class="col-12 col-sm-6 col-md-4">
        <div class="clearfix">
          <!-- Datepicker docs https://github.com/charliekassel/vuejs-datepicker -->
          <!-- input-class="btn btn-outline-secondary date-btn" -->
          <div class="input-group mb-3">
            <Datepicker v-model="date" input-class="float-left form-control" :calendar-button="true"
                        :calendar-button-icon="'Calendar'" :typeable="true" :disabled-dates="disabledDates"
                        placeholder="Dismiss until" name="date" format="MM/dd/yyyy" />
            <div class="input-group-append">
              <button type="button" class="btn btn-primary" :disabled="!date || selected.length === 0"
                      @click="dismiss">
                Select
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!loading" class="row pl-5">
      <div class="col-12">
        <!-- bootstrap-vue table docs https://bootstrap-vue.js.org/docs/components/table -->
        <b-table class="mt-5" thead-tr-class="grid-title" hover
                 :current-page="currentPage" :per-page="resultsPerPage" :responsive="true"
                 :items="localOrganizations" :fields="fields" :no-provider-paging="false"
                 :filter="filterTable" striped @filtered="filterUpdating"
                 @sort-changed="currentPage = 1">
          <!-- Header data -->
          <template slot="HEAD_status" slot-scope="data">
            <div class="text-center">
              {{ data.label }}
            </div>
          </template>
          <template slot="HEAD_company" slot-scope="data">
            <div class="text-center">
              {{ data.label }}
            </div>
          </template>
          <template slot="HEAD_type" slot-scope="data">
            <div class="text-center">
              {{ data.label }}
            </div>
          </template>
          <template slot="HEAD_milestone_1" slot-scope="data">
            <div id="milestone1" class="text-center" @click.stop>
              {{ data.label }}
            </div>
            <!-- bootstrap-vue popover docs https://bootstrap-vue.js.org/docs/components/popover/ -->
            <!-- Milestone 1 header -->
            <b-popover slot="default" target="milestone1" triggers="hover focus">
              <p class="font-weight-bold">
                Schools & Teams
              </p>
              <ol>
                <li>Number of accounts created in the last 4 months</li>
                <br>
                <p>
                  <i>Utilization of 80% of available accounts will indicate 'green' status</i>
                </p>
              </ol>
            </b-popover>
          </template>
          <!-- Milestone 2 header -->
          <template slot="HEAD_milestone_2" slot-scope="data">
            <div id="milestone2" class="text-center" @click.stop>
              {{ data.label }}
            </div>
            <b-popover slot="default" target="milestone2" triggers="hover focus">
              <p class="font-weight-bold">
                Schools
              </p>
              <ol>
                <li>
                  Number of students to watch at least one video in the last two months
                </li>
                <br>
                <p>
                  <i>Any activity in the past two months will indicate 'green'</i>
                </p>
              </ol>
              <hr>
              <p class="font-weight-bold">
                Teams
              </p>
              <ol>
                <li>
                  Number of users to watch at least one video in the past month
                </li>
                <br>
                <p><i>Three views are required to indicate 'green'</i></p>
              </ol>
            </b-popover>
          </template>
          <!-- Milestone 3 header -->
          <template slot="HEAD_milestone_3" slot-scope="data">
            <div id="milestone3" class="text-center" @click.stop>
              {{ data.label }}
            </div>
            <b-popover slot="default" target="milestone3" triggers="hover focus">
              <p class="font-weight-bold">
                Teams
              </p>
              <ol>
                <li>
                  % of users that watched at least 1 lesson in the last 2
                  months?
                </li>
              </ol>
            </b-popover>
          </template>
          <!-- Body data -->
          <template slot="select" slot-scope="data">
            <b-check :id="'selected-' + data.item.id" v-model="selected" :value="data.item" />
          </template>
          <template slot="status" slot-scope="data">
            <div class="text-center" :class="statusLabel(data.item.status)" />
          </template>
          <template slot="company" slot-scope="data">
            <a :id="'company-' + data.item.id" :href="config.ADMIN_PANEL_URL + '/backend/new/customers_mod.asp?idc=' + data.item.rawData.idCustomer" target="_blank">
              {{ data.item.company }}
            </a>
            <!-- Popover for admin/instructor list -->
            <b-popover slot="default" :target="'company-' + data.item.id" triggers=" hover focus"
                       title="Instructors">
              <div v-if="data.item.admins.length > 0">
                <div v-for="admin in data.item.admins" :key="admin.id">
                  {{ admin.name }}
                  {{ admin.lastName }}
                </div>
              </div>
              <div v-if="data.item.admins.length === 0">
                N/A
              </div>
            </b-popover>
          </template>
          <!-- Milestone 1 column -->
          <template slot="milestone_1" slot-scope="data">
            <div v-if="data.item.rawData.accountsCreated4Months" class="text-center">
              {{ data.item.rawData.accountsCreated4Months }}
            </div>
            <div v-else class="text-center">
              -
            </div>
          </template>
          <!-- Milestone 2 column -->
          <template slot="milestone_2" slot-scope="data">
            <div v-if="data.item.type === 'academic'">
              <div v-if="data.item.rawData.aliasWatchedVideos2Months" class="text-center">
                {{ data.item.rawData.aliasWatchedVideos2Months }}
              </div>
              <div v-else class="text-center">
                0
              </div>
            </div>
            <div v-else>
              <div v-if="data.item.rawData.aliasWatches1Month" class="text-center">
                {{ data.item.rawData.aliasWatches1Month }}
              </div>
              <div v-else class="text-center">
                0
              </div>
            </div>
          </template>
          <!-- Milestone 3 column -->
          <template slot="milestone_3" slot-scope="data">
            <div v-if="data.item.type === 'team'">
              <div v-if="data.item.processedData.percentageAliasWatched2Months >= 1" class="text-center">
                <font-awesome-icon icon="check" size="2x" />
              </div>
              <div v-else class="text-center">
                {{ formatPercent(data.item.processedData.percentageAliasWatched2Months) }} %
              </div>
            </div>
            <div v-else class="text-center">
              N/A
            </div>
          </template>
        </b-table>
      </div>
    </div>
    <div v-if="!loading" class="row py-4">
      <div class="col-12">
        <b-pagination v-model="currentPage" class="justify-content-center" size="md"
                      :total-rows="totalRows" :per-page="resultsPerPage" />
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import _ from 'lodash';

export default {
  name: 'HealthCheck',
  components: {
    Datepicker
  },
  data() {
    return {
      loading: false,
      disabledDates: {
        to: new Date()
      },
      date: null,
      localOrganizations: [],
      filterTable: null,
      currentPage: 1,
      resultsPerPage: 100,
      totalRows: null,
      agent: null,
      cstAgents: [],
      selected: [],
      // Fields in table
      fields: [
        'select',
        { key: 'status', label: 'Status', sortable: true },
        { key: 'company', label: 'Account Name', sortable: true },
        { key: 'type', label: 'Account Type', sortable: true },
        { key: 'milestone_1', label: 'Milestone: 1 Enrollment' },
        { key: 'milestone_2', label: 'Milestone: 2 Lesson Views' },
        { key: 'milestone_3', label: 'Milestone: 3 Engagement' }
      ],
      validActiveAccount: true
    };
  },
  computed: {
    organizations() {
      return this.$store.getters['healthcheck/getOrganizations'];
    }
  },
  watch: {
    agent: function(val, oldval) {
      this.filterTableRecords(val);
    },
    selected: function(val, oldval) {
      this.validateActive(val);
    }
  },
  created() {
    this.loading = true;
    this.init();
  },
  methods: {
    init() {
      this.$store.dispatch('healthcheck/loadAccounts').then(
        (response) => {
          this.totalRows = this.organizations.length;
          this.localOrganizations = this.organizations;
          this.loadCSTAgent().then(
            (response) => {
              this.loading = false;
            },
            (error) => {
              console.log(error);
            }
          );
        },
        (error) => {
          console.log(error);
          this.loading = false;
        }
      );
    },
    /**
     * Set organizations to local organizations object
     * @param agentId
     */
    filterTableRecords(agentId) {
      if (!agentId) {
        this.localOrganizations = this.organizations;
      } else {
        let agentFilter = function(agent) {
          return agent.cst_agent_idc === agentId;
        };
        this.localOrganizations = this.organizations.filter(agentFilter);
      }
    },
    /**
     * On filter reset page to one and update number of pages
     * @param filteredItems
     */
    filterUpdating(filteredItems) {
      this.currentPage = 1;
      this.totalRows = filteredItems.length;
    },
    /**
     * Find uniuqe
     */
    loadCSTAgent() {
      return new Promise((resolve, reject) => {
        let uniqueAgents = _.uniqBy(this.localOrganizations, 'cst_agent_idc');
        for (let i = 0; i < uniqueAgents.length; i++) {
          if (!uniqueAgents[i].cst_agent_idc) {
            uniqueAgents[i].cst_agent_name = 'All';
          }
          this.cstAgents.push({
            value: uniqueAgents[i].cst_agent_idc,
            text: uniqueAgents[i].cst_agent_name
          });
          if (uniqueAgents[i].cst_agent_idc === this.user.id) {
            this.agent = uniqueAgents[i].cst_agent_idc;
          }
        }
        resolve();
      });
    },
    formatPercent(num) {
      return Math.round(num * 100).toFixed(0);
    },
    formatRoundNumber(num) {
      return Math.round(num);
    },
    statusLabel(status) {
      switch (parseInt(status)) {
      case 1:
        return 'status-1';
      case 2:
        return 'status-2';
      case 3:
        return 'status-3';
      default:
        return 'status-4';
      }
    },
    /**
     * If account is active disable activate button
     * @param {Array} selected items array
     */
    validateActive(selected) {
      let found = false;
      for (let i = 0; i < selected.length; i++) {
        if (selected[i].status !== 0) {
          this.validActiveAccount = true;
          found = true;
          break;
        }
      }
      if (!found) {
        this.validActiveAccount = false;
      }
    },
    /**
     * Build patch data object
     * @param {Object} selected account object
     * @param {Boolean} activate boolean
     */
    buildPatchData(selected, activate) {
      let date = new Date(this.date).toISOString();
      if (selected.health_id && selected.dismissDate && activate) {
        return {
          op: 'remove',
          path: '/' + selected.health_id + '/dismiss_until'
        };
      } else {
        return {
          op: 'add',
          path: '/' + selected.health_id + '/dismiss_until/' + date
        };
      }
    },
    /**
     * Called when user chooses dismiss until date and clicks submit
     */
    dismiss() {
      let postData = [];
      let patchData = [];
      let date = new Date(this.date).toISOString();
      for (let i = 0; i < this.selected.length; i++) {
        if (!this.selected[i].health_id) {
          postData.push({
            organization_id: this.selected[i].id,
            dismiss_until: date
          });
        } else {
          patchData.push(this.buildPatchData(this.selected[i], false));
        }
      }
      if (postData.length > 0) {
        this.$store.dispatch('healthcheck/dismissUntil', postData).then(
          (response) => {
            this.updateAccount(true, response.data);
          },
          (error) => {
            console.log(error);
          }
        );
      }
      if (patchData.length > 0) {
        this.$store.dispatch('healthcheck/makeActive', patchData).then(
          (response) => {
            this.updateAccount(true);
          },
          (error) => {
            console.log(error);
          }
        );
      }
    },
    /**
     * Activate dismissed account
     */
    activate() {
      let patchData = [];
      for (let i = 0; i < this.selected.length; i++) {
        patchData.push(this.buildPatchData(this.selected[i], true));
      }
      this.$store.dispatch('healthcheck/makeActive', patchData).then(
        (response) => {
          this.updateAccount(false);
        },
        (error) => {
          console.log(error);
        }
      );
    },
    /**
     * Update locally selected account once dismissed or activated
     * @param {Boolean}  dismiss vs activate
     * @param {Array} post data array
     */
    updateAccount(dismiss, post) {
      for (let i = 0; i < this.selected.length; i++) {
        if (dismiss) {
          if (post) {
            for (let j = 0; j < post.length; j++) {
              if (post[j].organization_id === this.selected[i].id) {
                this.selected[i].health_id = post[j].id;
              }
            }
          }
          this.selected[i].status = 0;
          this.selected[i].dismissDate = this.date;
        } else {
          this.selected[i].status = this.findStatus(this.selected[i]);
        }
      }
      this.localOrganizations.sort((a, b) => b.status - a.status);
      this.selected = [];
      this.loading = false;
    },
    /**
     * Calculate status for each account after dismiss or activate
     * @param {Object} selected account data
     */
    findStatus(selected) {
      // Schools
      // Have student accounts been created in the last 4 months? (Blake requested 4 months since this is a  typical semester term)
      // Have students watched assignment lessons in the last 2 months?
      if (selected.rawData.superAdmin === 0) {
        const a = selected.rawData.accountsCreated4Months;
        const b = selected.rawData.aliasWatchedVideos2Months;
        if (a > 0 && b > 0) {
          return 1;
        } else if (a > 0 || b > 0) {
          return 2;
        } else {
          return 3;
        }
        // Teams
        // Are 80% of the available accounts created?
        // Average lesson views per user last month?
        // % of users that watched at least 1 lesson in the last 2 months? (Serves as a control on 2 since one user can have many views, throwing off the average)
      } else {
        const a = selected.processedData.percentageAccountsCreated;
        const b = selected.rawData.aliasWatches1Month;
        const c = selected.processedData.percentageAliasWatched1Month;
        if (a >= 0.8 && b > 0 && c > 0) {
          return 1;
        } else if (
          (a >= 0.8 && b > 0) ||
          (b > 0 && c > 0) ||
          (a >= 0.8 && c > 0)
        ) {
          return 2;
        } else {
          return 3;
        }
      }
    }
  }
};
</script>

<style></style>
